body {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 14px;
}

img.logo {
  width: 150px;
  height: auto;
}

img.logo-landing-page {
  width: 320px;
  height: auto;
}

.user-section {
    position: absolute;
    top: 10px;
    right: 10px;
    text-align: right;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full height of the viewport */
    flex-direction: row; /* Set default flex direction */
}

.centered-box {
    width: 350px;
    height: 400px;
    background-color: #fff;
    padding: 0px 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.container-signup {
    width: 300px;
    height: 640px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: left;
    position: absolute;
    top: 30px;
    left: 30px;
}

/* Responsive styles for smaller screens */
@media screen and (max-width: 768px) {
    .container {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        height: auto;
    }

   .container-signup, .centered-box {
        width: 100%;
        height: auto;
        position: static;
        margin: 10px 0;
    }
}

.signup-text {
    color: #000;
    font-size: 12px;
    margin: 7px 0;
    text-align: center;
}

.user-section-logged-off {
    text-align: center;
    padding-bottom: 10px;
}

.register-now {
    text-align: center;
    padding: 10px 0;
}

.username {
    color: #000;
    font-weight: 500;
    font-size: 0.8rem;
    margin-bottom: 5px; /* Space between username and buttons */
}

.logoff-text {
    color: #000;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 10px;
    text-align:center;
}

.sign-up-checkbox-text {
    color: #000;
    font-weight: 500;
    font-size: 16px;
    margin: 7px 0;
    text-align:left;
}

.sign-up-text {
    color: #000;
    font-weight: 500;
    font-size: 16px;
}

div.user-section button, div.user-section-logged-off button, div.container-signup button {
    display: inline-table;
    background-color: #344767;
    border: none;
    border-radius: 0.5rem;
    color: #f7f7f7;
    padding: 8px;
    text-align: center;
    margin: 2px 8px;
    cursor: pointer;
    transition: 0.1s; /* Transition effect */
    font-weight: 500;
    font-size: 1rem
}

div.user-section button:hover, div.user-section-logged-off button:hover, div.container_sign_up button:hover {
    background-color: #69798C;
}

input[type="file"] {
  cursor: pointer;
}

.chat-bar {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.chat-bar button {
  padding: 5px 10px;
  margin-right: 10px;
}

.chat-bar input {
  flex-grow: 1;
  padding: 5px;
  border: 1px solid #ccc;
}

.MuiFormControl-root css-2m9kme-MuiFormControl-root {
    padding: 300px;
}