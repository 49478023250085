ul.no-bullets  {
  list-style-type: none;
  margin: 0;
  padding: 10px 0 0 0;
}

ul.with-bullets  {
  margin: 0 0 0 20px;
  padding: 10px 0 0 0;
}

ul.with-bullets-indent  {
  margin: 0 0 0 30px;
  padding: 10px 0 0 0;
}

.centerSampleImg {
    margin: auto;
    width: 724px;
}

.centerSampleDeckImg {
    margin: auto;
    width: 900px;
}