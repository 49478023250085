input {
  cursor: pointer;
}

.select-non-functional {
    color: lightGrey;
}

div#rangenavigator_calendar_popup {
     margin: 0 auto;
     margin-bottom:20px;
}