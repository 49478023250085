.summary-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

/* Summary table */
.summary-table thead tr {
  background-color: blue;
  color: #ffffff;
  text-align: left;
}

.summary-table th,
.summary-table td {
  padding: 12px 15px;
}

.summary-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.summary-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.summary-table tbody tr:last-of-type {
  border-bottom: 2px solid blue;
}

.summary-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}

select.style-select {
    cursor: pointer;
}

.select-spacing {
  margin-right: 10px;
}

/* ###################### */
/* Log tab table styling */
.log-table {
    width: 50%;
    border-collapse: collapse;
}

.log-table th, .log-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.log-table th {
    background-color: #f2f2f2;
    font-size: 16px;
}

.log-table td {
    font-size: 14px;
}

.log-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.log-table tr:hover {
    background-color: #ddd;
}

.data-table-row-odd {
  background-color: #f9f9f9; /* Light gray color for odd rows */
}

.data-table-row-even {
  background-color: #fff; /* White color for even rows */
}

.th.MuiBox-root {
   height: 5px;
}

.td.MuiBox-root {
   height: 15px;
}